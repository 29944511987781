<template>
    <div v-if="loading">
        <LoadingSpinner class="col-12" />
    </div>
    <div v-else class="row">
        <div class="col-12">
            <h4>{{ $t('trigger.entity_type.resultat_analyse') }}</h4>
            <h5>{{ horse_names }}</h5>
        </div>
        <template  v-if="analyses.length == 0 || site_prelevement.length == 0">
            <div class="mx-auto">
                <div class="alert alert-warning">{{ $t('acte.no_analyse_available') }}</div>
            </div>
            <div class="col-12">
                <div class="text-center mt-3">
                    <button @click="goToNext" class="btn btn-secondary rounded-pill">
                        <font-awesome-icon :icon="['fal', 'times']" /> {{ $t('global.annuler') }}
                    </button>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="col-12">
                <span class="btn btn-link" @click="allNegative()">{{ $t('acte.analyse_negative') }} <font-awesome-icon :icon="['fal', 'long-arrow-right']" /></span>
            </div>
            <div class="col-12 row mb-3 result_analyse_item" v-for="(analyse, idx) in analyses" :key="idx">
                <div class="col-12">
                    <h3>{{ $t("reponses." + analyse) }}</h3>
                </div> 
                <div class="col-3" v-for="(site, idx2) in site_prelevement" :key="idx2">
                    <h5>{{ site }}</h5>
                	<QuestionForm
                        :ref="'QuestionForm_' + idx + '_' + idx2"
                        v-if="results.length > 0"
        				question_type="horse_actes_result_type"
                        :question_fk="result_type.resulttype_id "
                        reponse_type="horse_actes_result"
                        :reponse_fk="results[0].result_id"
                        :params_wbs="paramsWbs"
                        preselect="NEG"
        			/>
                </div>
                <div class="col-12">
                    <hr>
                </div>
            </div>
            <div class="col-12">
    	        <div class="text-center mt-3">
    	            <button @click="saveResult" class="btn btn-primary rounded-pill">
    	                <font-awesome-icon :icon="['far', 'save']" /> {{ $t('global.valider') }}
    	            </button>
    	        </div>
    	    </div>
        </template>
    </div>
</template>

<script type="text/javascript">
    import { EventBus } from "EventBus"
    import Actes from '@/mixins/Actes'
	import ResultatAnalyse from '@/mixins/ResultatAnalyse'
    import Shutter from "@/mixins/Shutter.js"
    import ShutterTriggers from '@/mixins/shutters-manager/Triggers.js'
    import Common from '@/assets/js/common.js'

    export default {
        name: "ResultAnalyse",
        props: {
            horses_ids: { type: Array, default: () => [] },
            date_acte: { type: Date, default: () => new Date() },
            shutter_name: { type: String, default: () => "" },
            next: { type: Array, default: () => [] },
            acte_type: { type : Object, default: () => {} },
            actes_ids: { type : Array, default: () => [] }
        },
        mixins: [Actes, ResultatAnalyse, Shutter, ShutterTriggers],
        data () {
            return {
                result_type: {},
                results: [],
                actes: [],
                analyses: [],
                site_prelevement: [],
                loading: false,
                horse_names: ''
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loading = true
        		this.result_type = await this.getResultTypeByActe(this.acte_type.actestype_code)
                await this.init_actes()
                await this.init_result()
                this.loading = false

                this.formatteHorsesNames()
            },

            async init_actes() {
                // const acte_negatif = this.actes_ids.filter(id => id < 0)
                // if(acte_negatif.length > 0) {
                //     await this.$sync.force(true, true)
                // }

                await Common.asyncForEach(this.actes_ids, async (acte_id) => {
                    let id = this.$sync.replaceWithReplicated('horse_actes', acte_id)

                    const acte = await this.getActeWithReponse(id)
                    this.actes.push(acte)
                })

                const analyse = this.actes[0].reponse.find(rep => rep.reponse_question_code == "Analyses demandées")
                const site_prelevement = this.actes[0].reponse.find(rep => rep.reponse_question_code == "Site de prélèvement")

                let analyses_tab, site_prelevement_tab = []
                if(!analyse.reponse_data.every(el => el === null)) {
                    analyses_tab = analyse.reponse_data
                }
                if(!site_prelevement.reponse_data.every(el => el === null)) {
                    site_prelevement_tab = site_prelevement.reponse_data
                }
                this.analyses = analyses_tab.filter(analyse => analyse !== null)
                this.site_prelevement = site_prelevement_tab.filter(site => site !== null)
            },

            async init_result() {
                await Common.asyncForEach(this.actes_ids, async (acte_id) => {
                    const result = await this.getResultByActe(acte_id)
                    this.results = this.results.concat(result)
                })

                if(this.results.length == 0) {
                    this.results[0] = {result_id: 0}
                }
            },

            async saveResult() {
                let params = []
                await Common.asyncForEach(this.analyses, async (analyse) => {
                    await Common.asyncForEach(this.site_prelevement, async (site) => {
                        const results = this.results.filter(result => result.result_analyse == analyse && result.result_prelevement == site)
                        const results_ids = results.map(result => result.result_id)

                        const ref = "QuestionForm_"+this.analyses.indexOf(analyse)+"_"+this.site_prelevement.indexOf(site);
                        const reponses = await this.$refs[ref][0].prepareReponses()
                        // await this.updateResultAnalyse(results_ids, reponses)
                        params.push({results_ids, reponses})
                    })
                })
                await this.updateResultAnalyseLot(params)

                this.goToNext()
            },

            allNegative() {
                this.analyses.forEach(analyse => {
                    this.site_prelevement.forEach(site => {
                        const ref = "QuestionForm_"+this.analyses.indexOf(analyse)+"_"+this.site_prelevement.indexOf(site);
                        this.$refs[ref][0].setReponse('Negatif', 'Résultat')
                        this.$refs[ref][0].setReponse('Negatif', 'Interprétation')
                    })
                })
            },

            goToNext() {
                const horse_id = this.actes[0].actes_horse

                if(this.$route.name == "horseSanitaire") {
                    this.ok()
                    this.shutterPanel().close('resultat_analyse')
                    EventBus.$emit('SanitaireHorse::refresh')
                }
                else {
                    this.$router.push({ name: 'horseSanitaire', params: {horse_id} })
                }
            },

            async formatteHorsesNames() {
                const horses = await this.getHorsesByIds(this.horses_ids)
                this.horse_names = horses.map(horse => horse.horse_nom).join(', ')
            }
        },
        computed: {
        	paramsWbs() {
                return {
                    horses_ids: this.horses_ids,
                    actes_date: this.date_acte,
                    acte_type_code: this.result_type.resulttype_id
                }
			}
        },
        components: {
            QuestionForm: () => import('@/components/Questions/QuestionForm'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
        }
    };
</script>